@import 'normalize';
@import 'constants';

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--bodyFont);
  background-color: var(--dh-white);
  //color: var(--colorPrimary);
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;

  &.active {
    color: var(--colorLink);

    &:hover {
      color: var(--colorLinkHover);
    }
  }
}

p {
  font-size: 14px;
  font-weight: 500;
  color: var(--dh-darkgrey);
}

* {
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Tablet & Desktop */
@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }

  p {
    font-size: 18px;
  }
}