@use 'colors.module.scss';

:root {
  /* Colors Rebranding */
  --dh-black: #000000;
  --dh-black1: #060606;
  --dh-black2: #09090a;
  --dh-white: #ffffff;
  --dh-white1: #fcfcfe;
  --dh-white2: #eeeef2;
  --dh-white3: #dedee2;
  --dh-white4: #ceced0;
  --dh-white5: #dcdcdc;
  --dh-white6: #e2e2e2;
  --dh-blue200: #8585ff;
  --dh-blue400: #5c5cff;
  --dh-blue500: #3333ff;
  --dh-blue600: #2929cc;
  --dh-green500: #00cc7e;

  /* gray-scale */
  --dh-darkgrey: #303030;
  --dh-mediumgrey: #6c757d;
  --dh-lightgrey: #f5f5f5;
  --dh-gray2: #18181b;
  --dh-gray5: #41414a;
  --dh-gray6: #4c4c57;
  --dh-gray7: #7d7d8e;
  --dh-gray8: #a5a5b4;
  --dh-gray9: #767886;
  --dh-gray11: #2c2c31;
  --dh-gray12: #999999;

  /* Body */
  --bodyFont: 'Montserrat', sans-serif;
  --bodyBackground: #f1f1f3;
  --textColor: var(--dh-mediumgrey);

  /* Colors */
  --colorPrimary: #050038;
  --colorActive: #4349b5;
  --colorHighlight: #ffc93d;
  --colorGreen: #1fbb60;
  --colorError: #da1616;
  --colorOrange: #ff9130;

  --colorLink: var(--dh-black);
  --colorLinkHover: var(--dh-darkgrey);

  /* Transitions */
  --hoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

  /* Aside */
  --asideFixedWidth: 400px;
  --asideRelativeWidth: 25%;
  --asideDesktopPadding: 40px;
  --asideMobilePadding: 24px;
  --asideFixedMobileHeigth: 87px;

  /* Forms */
  --formMaxWidth: 536px;
}
