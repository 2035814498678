/* Colors Rebranding */
$dh-black: #000000;
$dh-black200: #09090A;
$dh-white: #ffffff;
$dh-white200: #EEEEF2;
$dh-white300: #DEDEE2;
$dh-darkgrey: #303030;
$dh-mediumgrey: #6c757d;
$dh-lightgrey: #f5f5f5;
$dh-green500: #00CC7E;
$dh-blue400: #5C5CFF;
$dh-error: #DE2424;
$dh-grey: #FCFCFE;
$dh-grey2: #41414A;

:export {
  dhblack: $dh-black;
  dhblack200: $dh-black200;
  dhwhite: $dh-white;
  dhwhite200: $dh-white200;
  dhwhite300: $dh-white300;
  dhdarkgrey: $dh-darkgrey;
  dhmediumgrey: $dh-mediumgrey;
  dhlightgrey: $dh-lightgrey;
  dhgreen500: $dh-green500;
  dhblue400: $dh-blue400;
  dherror: $dh-error;
  dhgrey: $dh-grey;
  dhgrey2: $dh-grey2;
}
